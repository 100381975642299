<template>
  <ion-page>
    <div class="background--login" />
    <ion-content
      :scroll-y="false"
      class="ion-padding login-top-text"
    >
      <ion-grid>
        <div class="d-flex flex-column login">
          <Header @onBackClick="$router.push('signup')" />
          <transition
            name="fade2"
            mode="out-in"
          >
            <div v-if="keyboardStatus !== 'open'">
              <h1>
                {{ $t('Welcome back') }}
              </h1>
              <ion-text
                class="text--gray"
              >
                {{ $t('loginPageText') }}
              </ion-text>
            </div>
          </transition>
          <ion-button
            class="google-signin"
            @click="signInWithGoogle"
          >
            <ion-img
              style="max-width: 35px; margin-right: 5px;"
              :src="google"
            />
            <label
              class="text--gray"
            >{{ $t('Sign in with Google') }}</label>
          </ion-button>
          <ion-button
            v-if="platform.includes('ios')"
            class="apple-signin"
            @click="signInWithApple"
          >
            <ion-img
              style="max-width: 23px; margin-right: 10px; margin-bottom: 5px;"
              :src="apple"
            />
            <label
              class="text--gray"
            >{{ $t('Sign in with Apple') }}</label>
          </ion-button>
          <hr>
          <form
            class="login-form form--dark flex-1"
            :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
            @submit.prevent="handleLogin"
          >
            <ion-item lines="none">
              <ion-label position="floating">
                {{ $t('emailAddress') }}
              </ion-label>
              <ion-input
                id="username"
                v-model="email"
                autocomplete="email"
                enterkeyhint="done"
                type="email"
                required
                @keydown.enter="closeKeyboard"
              />
            </ion-item>
            <ion-item lines="none">
              <ion-label position="floating">
                {{ $t('password') }}
              </ion-label>
              <ion-input
                id="password"
                v-model="password"
                enterkeyhint="done"
                :type="passwordInputModeVisible ? 'text' : 'password'"
                required
                @keydown.enter="closeKeyboard"
              />
              <div
                slot="end"
                @click="passwordInputModeChange()"
              >
                <i
                  class="mdi pwd-icon"
                  :class="passwordIcon"
                />
              </div>
            </ion-item>
            <ion-button
              expand="block"
              type="submit"
              :disabled="!email || !password"
              style="margin-bottom: 25px"
            >
              <ion-spinner
                v-if="loading"
                name="crescent"
              />
              <ion-label v-else>
                {{ $t('Sign In') }}
              </ion-label>
            </ion-button>
            <a>
              <!-- <ion-text
              @click="presentAlert('Not implemented')"
            >
              Forgot password
            </ion-text> -->
              <router-link
                to="/resetpassword"
                class="signin-link"
              >
                {{ $t('Forgot Password?') }}
              </router-link>
            </a>
          </form>

          <ion-row
            class="dont-have-an-account-login"
          >
            <ion-col style="font-weight: 400;">
              <ion-text
                class="text--gray"
                style="font-weight: 400;"
              >
                {{ $t('Dont have an account?') }}
              </ion-text>
              <router-link
                to="/signup"
                style="font-weight: 400;"
                class="signin-link"
              >
                {{ $t('Sign Up') }}
              </router-link>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { alertController, loadingController } from '@ionic/vue'
import { useRouter } from 'vue-router'

import { GooglePlus } from '@ionic-native/google-plus/'
import { Plugins } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'

import jwtDecode from 'jwt-decode'

import google from '@/assets/images/google.png'
import apple from '@/assets/images/apple.png'
import Header from '../components/Header.vue'
const { SignInWithApple } = Plugins

export default {
  name: 'Login',
  components: {
    Header
  },
  data () {
    const router = useRouter()
    const leftIcon = '../../img/icons/back-arrow.png'
    return {
      router,
      email: '',
      password: '',
      passwordInputModeVisible: false,
      loading: false,
      leftIcon,
      google,
      apple
    }
  },
  computed: {
    passwordIcon () {
      if (this.passwordInputModeVisible) {
        return 'mdi-eye'
      } else {
        return 'mdi-eye-off'
      }
    },
    keyboardStatus () {
      return this.$store.state.keyboardStatus
    },
    platform () {
      return this.$store.state.platform
    }
  },
  methods: {
    passwordInputModeChange () {
      if (this.passwordInputModeVisible) {
        this.passwordInputModeVisible = false
      } else {
        this.passwordInputModeVisible = true
      }
    },
    async handleLogin () {
      this.loading = true
      // this.openLoaderOverlay()
      await this.$store
        .dispatch('LoginUser', {
          email: this.email.toLowerCase(),
          password: this.password
        })
        .then(response => {
          this.email = ''
          this.password = ''
          if (response) {
            this.$router.replace('/')
            this.loading = false
            // this.dismissLoader()
          }
        })
        .catch(error => {
          this.email = ''
          this.password = ''
          // this.dismissLoader()
          this.loading = false
          this.presentAlert(error.response.data)
        })
    },
    signInWithGoogle () {
      GooglePlus.login({})
        .then(response => {
          const name = response.displayName
          const email = response.email
          console.log(name)
          this.$store.dispatch('OAuthSingUpCheckCredentials', { name, email })
            .then(response => {
              // console.log(response)
              if (response.data.type === 'Success') {
                this.$router.replace('/')
              } else if (response.data.type === 'False') {
                this.presentAlertApple('Account does not exits please sign up')
              } else {
                this.presentAlertApple('Something has failed please try it again')
              }
            })
            .catch((error) => {
              console.log('Sign in errpr')
              console.log(error)
              this.presentAlertApple(error.data)
            })
        })
        .catch(error => {
          // this.presentAlertApple(error)
          console.log(error)
        })
    },
    signInWithApple () {
      SignInWithApple.Authorize()
        .then(async (res) => {
          if (res.response && res.response.identityToken) {
            const name = `${res.response.givenName} ${res.response.familyName}`
            let email = res.response.email
            const token = res.response.identityToken
            if (email === null) {
              const decoded = jwtDecode(token)
              email = decoded.email
            }
            this.$store.dispatch('OAuthSingUpCheckCredentials', { name, email })
              .then(response => {
                console.log(response)
                if (response.data.type === 'Success') {
                  this.$router.push('/')
                } else if (response.data.type === 'False') {
                  this.presentAlertApple('Account does not exits please sign up')
                } else {
                  this.presentAlertApple('Something has failed please try it again')
                }
              })
              .catch((error) => {
                console.log(error.data)
                this.presentAlertApple(error.data)
              })
          } else {
            // this.presentAlertApple('Something has failed please try it again')
          }
        })
        .catch((response) => {
          // this.presentAlertApple('Something has failed please try it again')
        })
    },
    async presentAlertApple (res) {
      const alert = await alertController
        .create({
          header: 'Fel',
          subHeader: '',
          message: res,
          buttons: [this.$t('close')]
        })
      await alert.present()
    },
    // async openLoaderOverlay () {
    //   const loading = await loadingController
    //     .create({
    //       cssClass: 'login-overlay',
    //       message: 'Logging you in...',
    //       spinner: 'dots'
    //     })

    //   await loading.present()
    // },
    dismissLoader () {
      loadingController.dismiss()
    },
    async presentAlert (message) {
      const alert = await alertController
        .create({
          header: 'Fel',
          subHeader: '',
          message: message,
          buttons: [this.$t('close')]
        })
      await alert.present()

      const { role } = await alert.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/theme/_colors";
@import "~@/theme/_backgrounds";

ion-content {
  .welcome-text {
    margin-top: 80px;
    font-size: 35px;
  }

  p {
    color: rgba( 217, 255, 217, 0.2);
  }

  ion-button {
    height: 50px;
    text-transform: none;
  }

.google-signin {
    width: 100%;
    min-height: 50px;
    border-radius: 5px;
    @include background-item--dark-transparent;
    --box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    padding: none;
    margin: 30px 0 10px 0;

    ion-icon {
      margin-right: 10px;
    }
  }
  .apple-signin {
    width: 100%;
    min-height: 50px;
    border-radius: 5px;
    @include background-item--dark-transparent;
    --box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    padding: none;
    margin: 0 0 8px 0;

    ion-icon {
      margin-right: 10px;
    }
  }

}

.login-form {
  margin-top: 15px;
}

.forgot-password {
  margin-top: 3vh;
  color: colors(primary, base);
}

.dont-have-an-account-login {
  margin: 30px 0;
}

.sign-up-link {
  margin-left: 5px;
  color: colors(primary, base);
}
hr {
  border-top: 2px solid rgba(255, 212, 164, 0.12);
  width: 100%;
}
.login-top-text{
  letter-spacing: 0.4px;
  ion-text{
    font-weight: 300;
  }
  h1{
    margin-top:10vh;
  }
}

@media only screen and (min-width: 750px) {
  .login {
    margin-left: 20vw;
    margin-right: 20vw;
  }
    .background--login::after {
    background-size: 100% auto;
  }
}
@media only screen and (min-width: 1000px) {
  .login {
    margin-left: 30vw;
    margin-right: 30vw;
  }
    .background--login::after {
    background-size: 100% auto;
  }
}
@media only screen and (min-width: 1300px) {
  .login {
    margin-left: 35vw;
    margin-right: 35vw;
  }
  .background--login::after {
    background-size: 100% auto;
  }
}

@media (max-width: 375px) and (max-device-height: 667px) {
  .dont-have-an-account-login {
    margin: 15px 0;
  }
}
</style>
